import { Form, FormLayout, Page, Select, TextField } from "@shopify/polaris";
import { addDoc, collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { firestore } from "../../firebase";
import { useQuery } from "@tanstack/react-query";
import { useField, useForm } from "@shopify/react-form";

export function AddRun() {
  const navigate = useNavigate();
  // const addRun = useCallback(() => {
  //   const env = prompt("Enter env", "main")
  //   addDoc(
  //     collection(firestore, "runs"),
  //     {
  //       env,
  //       status: 0,
  //       averageShoeSize: {
  //         system: "us",
  //         value: 10,
  //       },
  //       gender: 'male',
  //       timestamp: serverTimestamp()
  //     },
  //   ).then(
  //     ref => navigate("/run/" + ref.id)
  //   )
  // }, [])
  const [searchParams] = useSearchParams()
  const form = useForm({
    fields: {
      environment: useField(searchParams.get('environment') || 'main'),
      profile: useField(searchParams.get('profile') || 'random'),
      recording: useField(searchParams.get('recording') || 'random'),
      url: useField(searchParams.get('url') || ''),
    },
    onSubmit: async (values) => {
      if (values.url) {
        const url = new URL(values.url)
        if(url.searchParams.has("product")) {
          url.searchParams.delete("product")
          values.url = url.toString()
        }
      }
      await addDoc(
        collection(firestore, "runs"),
        {
          ...values,
          status: 0,
          timestamp: serverTimestamp()
        },
      ).then(
        ref => navigate("/run/" + ref.id)
      )
      return { status: 'success' }
    },
  })
  const { isLoading: profileLoading, data: profiles } = useQuery({
    queryKey: ['profiles'],
    queryFn: () => getDocs(collection(firestore, 'profiles')).then(
      s => [{ value: 'random', label: 'Random' }].concat(s.docs.map(d => ({ value: d.id, label: d.data().name })))
    ),
    refetchOnWindowFocus: false,
  });
  const { isLoading: recordingLoading, data: recordings } = useQuery({
    queryKey: ['recordings', form.fields.profile.value],
    queryFn: () => getDocs(query(
      collection(firestore, 'recordings'), where('profile', '==', form.fields.profile.value)
    )).then(
      s => [{ value: 'random', label: 'Random' }].concat(s.docs.map(d => ({ value: d.id, label: d.id })))
    ),
    refetchOnWindowFocus: false,
  });
  return <Page title="Add Run" backAction={{ content: "Runs", url: "/runs" }} primaryAction={{ content: 'Run', onAction: form.submit, loading: form.submitting }}>
    <Form onSubmit={() => { }}>
      <FormLayout>
        <Select label="Environment" options={[
          { label: "Main", value: "main" },
          { label: "Staging", value: "staging" },
        ]} value={form.fields.environment.value} onChange={form.fields.environment.onChange} />
        <TextField autoComplete="none" placeholder="Paste the QR code URL here, or leave this empty and it will open the default app" label="URL" value={form.fields.url.value} onChange={form.fields.url.onChange} />
        <Select label="Profile" disabled={profileLoading} options={profiles} value={form.fields.profile.value} onChange={form.fields.profile.onChange} />
        <Select label="Recording" disabled={!form.fields.profile.value || recordingLoading} options={recordings} value={form.fields.recording.value} onChange={form.fields.recording.onChange} />
      </FormLayout>
    </Form>
  </Page>
}